import styled from "styled-components";
import themeConfig from "../../lib/theme/tokens";
import { StyledLoginButtonNavbar } from "../../lib/theme/components/Layout";
import { useNavigate } from "react-router-dom";
import { useMixpanel } from "react-mixpanel-browser";


const StyledStartTrialContainer = styled.section`
    display: flex;
    flex-direction: column;
    background-color: ${themeConfig.colors.primary[25]};
    padding: 96px 0px 96px 0px;
    gap: 5px;
    align-items: center;
`;

const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
`;

const StyledTextHeader = styled.h2`
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;
`;

const StyledTextSubHeader = styled.p`
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`;

const StyledStartTrialButton = styled(StyledLoginButtonNavbar)`
    padding: 18px 28px 18px 28px;
    width: fit-content;
`;

function StartTrialSection() {
    const navigate = useNavigate();
    const mixpanel = useMixpanel();

    const handleBookADemo = () => {
        mixpanel.track("Book a demo - LP - last block");
        navigate("/book-a-demo");
    }
    return (
        <StyledStartTrialContainer>
            <StyledTextContainer>
                <StyledTextHeader>Start your free trial</StyledTextHeader>
                {/* <StyledTextSubHeader>Join over startups already growing with Pentugram.</StyledTextSubHeader> */}
            </StyledTextContainer>
            <StyledStartTrialButton onClick={handleBookADemo}>Book a demo</StyledStartTrialButton>
        </StyledStartTrialContainer>
    );
}

export default StartTrialSection;